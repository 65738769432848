<template>
<div style="min-height: 750px; font-size: 14px;">
    <!-- {{ d_statsList }} -->
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2">
        <i class="fa fa-desktop"></i> {{ StoreLangTranslation.data['dataset_filter'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4">
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="border-top: solid 1px lightblue;">
        <b-col sm="12" md="12" lg="4">
          <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
            <b-row>
              <b-col sm="12" lg="8">
                <h5>
                  {{ StoreLangTranslation.data['variables'][StoreLang] }}
                </h5>
              </b-col>
              <b-col sm="12" lg="4" style="text-align: right;">
                <b-button variant="outline-primary" size="sm" @click="f_resetProjectData()" :title="StoreLangTranslation.data['reset'][StoreLang]">
                  <i class="fa fa-refresh"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <template v-if="d_projectColumnListLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <template v-for="(col, col_ind) in d_project.column_list">
              <template v-if="col.param_type === 'select' || col.param_type === 'radiogroup'">
                <b-row>
                  <b-col sm="12" lg="4">
                    <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;">
                      <template slot="button-content">
                        <div style="font-size: 12px; white-space: pre-wrap;">
                          {{ col.name }}
                        </div>
                      </template>
                      <b-dropdown-item @click="f_addToColumnSelectedItems(col_ind, '')"><i class="fa fa-plus-square"></i> {{ StoreLangTranslation.data['all'][StoreLang] }} </b-dropdown-item>
                      <template v-if="['=', '<>'].indexOf(col.operation.value) !== -1">
                        <template v-for="(opt, opt_ind) in col.options">
                          <b-dropdown-item @click="f_addToColumnSelectedItems(col_ind, opt)"><i class="fa fa-plus-square"></i> {{ opt.label }}  </b-dropdown-item>
                        </template>
                      </template>
                    </b-dropdown>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" no-caret>
                      <template slot="button-content">
                        <div style="font-size: 12px; white-space: pre-wrap;">
                          <i class="fa fa-check"></i>
                          {{ col.operation ? col.operation.translation[StoreLang] : '' }}
                        </div>
                      </template>
                      <template v-for="(oper, oper_ind) in d_operatorList">
                        <template v-if="['=', '<>', 'is_null', 'is_not_null'].indexOf(oper.value) !== -1">
                          <b-dropdown-item @click="f_selectOperationForColumn(col_ind, oper)">
                            <i class="fa fa-check"></i>
                            {{ oper.translation[StoreLang] }}
                          </b-dropdown-item>
                        </template>
                      </template>
                    </b-dropdown>
                  </b-col>
                  <b-col sm="12" lg="4" style="text-align: center;">
                    <template v-if="col.selected_items.length > 0">
                      <template v-for="(selected_item, selected_item_ind) in col.selected_items">
                        <b-button variant="outline-warning" size="sm" style="font-size: 12px; margin: 2px; padding: 1px;" @click="f_deleteSelectedItem(col_ind, selected_item_ind)">
                          {{ selected_item.label }},
                        </b-button>
                      </template>
                    </template>
                    <template v-else>
                      {{ StoreLangTranslation.data['all'][StoreLang] }}
                    </template>
                  </b-col>
                </b-row>
                <hr>
              </template>
            </template>
            <template v-for="(col, col_ind) in d_project.column_list">
              <template v-if="col.param_type === 'integer' || col.param_type === 'float'">
                <b-row>
                  <b-col sm="12" lg="4" style="font-size: 12px; text-align: center;">
                     <strong>{{ col.name }}</strong>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-dropdown variant="white" size="sm" style="width: 100%; margin: 0px;" no-caret>
                      <template slot="button-content">
                        <div style="font-size: 12px; white-space: pre-wrap;">
                          <i class="fa fa-check"></i>
                          {{ col.operation ? col.operation.translation[StoreLang] : '' }}
                        </div>
                      </template>
                      <template v-for="(oper, oper_ind) in d_operatorList">
                        <b-dropdown-item @click="f_selectOperationForColumn(col_ind, oper)">
                          <i class="fa fa-check"></i>
                          {{ oper.translation[StoreLang] }}
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-form-input type="number" style="font-size: 12px;" v-model="col.selected" @change="f_wanalyzerProjectStatisticsAnalyzeList()"></b-form-input>
                  </b-col>
                </b-row>
                <hr>
              </template>
            </template>
            <b-row>
                <b-col sm="12" lg="6"> </b-col>
                <b-col sm="12" lg="6" style="text-align: right;">
                  <!--
                    <b-button variant="outline-primary" size="sm" @click="f_wanalyzerProjectStatisticsAnalyzeList()">
                      <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                    </b-button>
                  -->
                </b-col>
              </b-row>
          
          </template>
        </b-col>
        <b-col sm="12" md="12" lg="8" style="border-left: solid 1px lightblue;">
          <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
            <b-row>
              <b-col sm="12" lg="6">
                <h5>
                  {{ StoreLangTranslation.data['results'][StoreLang] }}
                </h5>
              </b-col>
              <b-col sm="12" lg="2">
              </b-col>
              <b-col sm="12" lg="2">
                <template v-if="!d_autoLoading">
                  <b-button variant="outline-primary" size="sm" @click="f_wanalyzerServicesTrigger()">
                    <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                  </b-button>
                </template>
              </b-col>
              <b-col sm="12" lg="2">
                <b-form-checkbox v-model="d_autoLoading"> {{ StoreLangTranslation.data['auto_loading'][StoreLang] }} </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="12">
              <b-tabs>
                <b-tab v-if="p_datasetView === 'yes'" :active="'dataset_view' === d_selectedTabItemId" @click="d_selectedTabItemId = 'dataset_view'">
                  <template slot="title">
                    <i class="fa fa-area-chart"></i>
                    <span v-if="'dataset_view' === d_selectedTabItemId" style="font-weight: bold;">
                    {{ StoreLangTranslation.data['dataset'][StoreLang] }}
                    </span>
                    <span v-else>
                    {{ StoreLangTranslation.data['dataset'][StoreLang] }}
                    </span>
                  </template>
                  <template v-if="'dataset_view' === d_selectedTabItemId">
                    <template v-if="d_datasetViewLoading">
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </template>
                    <template v-else>
                      <w-table2 style="transition: 2s; transform: none;" v-if="d_datasetWTable && d_datasetShow" :key="'dataset_view'" :p_tableDetails="d_datasetWTable"></w-table2>
                    </template>
                  </template>
                </b-tab>
                <template v-for="(proj_item, proj_id) in d_statisticResults">
                  <template v-for="(stats_item, stats_id) in proj_item">
                    <template v-for="(analyze_item, analyze_index) in stats_item.analyze_list">
                      <b-tab :active="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId" @click="d_selectedTabItemId = 'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index">
                        <template slot="title">
                          <i class="fa fa-area-chart"></i>
                          <span v-if="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId" style="font-weight: bold;">
                          {{ analyze_item.label }}
                          </span>
                          <span v-else>
                          {{ analyze_item.label }}
                          </span>
                        </template>
                        <template v-if="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index === d_selectedTabItemId">
                          <template v-if="d_statsViewLoading">
                              <b-spinner variant="primary" label="Spinning">statistics loading</b-spinner>
                          </template>
                          <template v-else>
                            <wanalyzer-analyze-view v-if="d_statsShow" :key="'analyze_' + proj_id + '_' + stats_id + '_' + analyze_index" :p_analyzeList="[analyze_item]"></wanalyzer-analyze-view>
                          </template>
                        </template>
                      </b-tab>
                    </template>
                  </template>
                </template>
              </b-tabs>
              <!-- <b-tabs>
                <template v-for="(col, col_ind) in d_project.column_list">
                  <template v-if="col.wai_output">
                    <b-tab :active="col.id === d_selectedColumn" @click="d_selectedColumn = col.id">
                      <template slot="title">
                        <i class="fa fa-area-chart"></i>
                        <span v-if="col.id === d_selectedColumn" style="font-weight: bold;">
                        {{ col.name }}
                        </span>
                        <span v-else>
                        {{ col.name }}
                        </span>
                      </template>
                      <template v-if="col.id === d_selectedColumn">
                        {{ col }}
                      </template>
                    </b-tab>
                  </template>
                </template>
              </b-tabs> -->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer title="Bilgi" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>
    <b-modal v-if="d_showGraphicView" id="d_showGraphicView" v-model="d_showGraphicView" scrollable hide-footer title="Bilgi" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      X: {{ chart_data.labels.length }},
      <select v-model="d_chartSize.chart_type" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="line">çizgi</option>
        <option value="bar">bar</option>
        <option value="scatter">nokta</option>
      </select>
      <select v-if="d_chartSize.y_mode ==='standart'" v-model="d_chartSize.x_mode" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="standart">x standart</option>
        <option value="x_ascending">x artan</option>
        <option value="x_descending">x azalan</option>
      </select>
      <select v-if="d_chartSize.x_mode ==='standart'" v-model="d_chartSize.y_mode" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="standart">y standart</option>
        <option value="y_ascending">y artan</option>
        <option value="y_descending">y azalan</option>
      </select>
      <select v-model="d_chartSize.x_interval_type" style="height: 30px; width: 100px;margin-right: 3px;">
        <option value="all">'x' tüm değerler</option>
        <option value="once_every_x_value">'x' her ... değerde bir</option>
      </select>
      <input v-if="d_chartSize.x_interval_type ==='once_every_x_value'" type="number" v-model="d_chartSize.x_interval" min="1" style="height: 30px; width: 100px;margin-right: 3px;" placeholder="x kaç değerde bir görülecek">
      <!--
        <div class="chart-wrapper" style="height: 100%;">
          <line-view :chart_data="chart_data" style="height:300px;" :height="300" />
        </div>
      -->
      <div v-if="d_showChartView" class="chart-wrapper" style="height: 100%;">
        <template v-if="d_chartSize.chart_type ==='line'">
          <line-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
        <template v-if="d_chartSize.chart_type ==='bar'">
          <bar-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
        <template v-if="d_chartSize.chart_type ==='scatter'">
          <scatter-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import {
  default as TimeLine
} from '@/components/widgets/TimeLine';
import {
  default as WTable2
} from '@/components/widgets/WTable2';
import {
  default as WanalyzerAnalyzeView
} from '@/components/widgets/WanalyzerAnalyzeView';
import moment from "moment";
import WanalyzerService from '@/services/wanalyzer';
import { data as operator_list } from '@/options/operator_list';
import { mapGetters } from 'vuex';
export default {
  name: 'WanalyzerDatasetAnalyze',
  components: {
    WTable2,
    WanalyzerAnalyzeView,
    TimeLine,
    LineView,
    BarView,
    ScatterView
  },
  props: {
    p_projectId: {
      type: String,
      required: false
    },
    p_statsId: {
      type: String,
      required: false
    },
    p_datasetView: {
      type: String,
      required: false
    }
  },
  data: () => {
    return {
      d_autoLoading: true,
      d_projectColumnListLoading: false,
      d_datasetViewLoading: false,
      d_statsViewLoading: false,
      d_datasetWTable: '',
      d_statsShow: true,
      d_datasetShow: true,
      d_selectedTabItemId: '',
      d_statisticResults: {},
      d_selectedColumn: '',
      d_operatorList: operator_list,
      d_showChartView: true,
      d_showGraphicView: false,
      d_chartSize: {
        'height': 0,
        'width': 0,
        'x_mode': 'standart',
        'y_mode': 'standart',
        'x_interval_type': 'all',
        'x_interval': 1,
        'chart_type': ''
      },
      chart_data: {},
      d_resetChartData: {},
      d_tableShow: true,
      d_tablePagination: {
        'start': 0,
        'end': 100,
        'current': 1,
        'perpage': 100,
        'all_count': 0
      },
      d_searchTextRow: '',
      d_fullScreen: false,
      d_statsList: {
        'analyze_list': [],
        'all_count': 0,
        'filter_stats': [],
        'filter_analyze_types': [],
        'selected_columns': []
      },
      d_projectId: '',
      d_statsId: '',
      d_filterData: {
        'selected_columns': [],
        'selected_stats': '',
        'selected_p_value': '',
        'selected_analyze_types': '',
        'user_score_list': '',
        'pagination': {
          'start': 0,
          'end': 1,
          'current': 1,
          'perpage': 1,
          'all_count': 0
        },
      },
      d_timeLineYearMonthMode: { 'year': 0, 'month': 0 },
      d_timeLineLeftToRight: [],
      mother_children: {
        'change_status': 0,
        'forceUpdate': 0
      },
      d_timeLineMode: false,
      d_isThereInputColumn: false,
      d_selectedTableCellFullText: '',
      d_showTableCellFullText: false,
      d_analyzeContinuing: false,
      d_sqlName: '',
      d_showSqlName: true,
      d_clientIdShow: true,
      d_project: { 'details': '', 'column_list': [] },
      d_resetProject: { 'details': '', 'column_list': [] },
      d_clientId: '',
      d_idType: '',
      d_wdmrId: '',
    }
  },
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  created () {
    if (this.p_datasetView === 'yes') {
      this.d_selectedTabItemId = 'dataset_view';
    }
    if (this.p_projectId) {
      this.d_projectId = this.p_projectId;
    } else if (!this.p_projectId) {
      if (this.$route.query.project_id !== undefined) {
        this.d_projectId = this.$route.query.project_id;
      }
    }

    if (this.d_projectId) {
      this.f_wanalyzerProjectDetailsColumnsWaiGet();
    }
  },
  methods: {
    f_createRuleAlgorithm: function () {
      let query_compile_str_array = ["("];
      let any_rule_columns_filled = false;
      let rule_data = {
        "query_list": [],
        "compile_str_array": [],
        "compile_str_text": [],
        "compile_str_date": []
      };
      for (let i in this.d_project.column_list) {
        if (['is_not_null'].indexOf(this.d_project.column_list[i].operation.value) !== -1) {
          let new_field_data = {
              "loc": [
                {"label": "Genel", "value": "general", "type": "group"},
                {"label": this.d_project.column_list[i].name, "value": this.d_project.column_list[i].index, "type": this.d_project.column_list[i].param_type}
              ],
              "using_data_if_not_reaching_record_data": "true",
              "val_list": [],
              "values": [],
              "significant": "true",
              "value_type": this.d_project.column_list[i].param_type,
              "operation": ""
          };
          let new_query_data = {
            "time_of_value": "any_value",
            "data_type": "old_recorded_data",
            "compile_str_array": ["(", 0, ")"],
            "fields": [],
            "compile_str": "",
            "significant": "true",
            "date": "1",
            "type": {"value": this.d_project.column_list[i].index, "label": this.d_project.column_list[i].name }
          };
          new_query_data.fields.push(new_field_data);
          rule_data.query_list.push(new_query_data);
          query_compile_str_array.push(rule_data.query_list.length - 1);
          query_compile_str_array.push('and');
          any_rule_columns_filled = true;
        } else if (['is_null'].indexOf(this.d_project.column_list[i].operation.value) !== -1) {
          let new_field_data = {
              "loc": [
                {"label": "Genel", "value": "general", "type": "group"},
                {"label": this.d_project.column_list[i].name, "value": this.d_project.column_list[i].index, "type": this.d_project.column_list[i].param_type}
              ],
              "using_data_if_not_reaching_record_data": "true",
              "val_list": [],
              "values": [],
              "significant": "false",
              "value_type": this.d_project.column_list[i].param_type,
              "operation": ""
          };
          let new_query_data = {
            "time_of_value": "any_value",
            "data_type": "old_recorded_data",
            "compile_str_array": ["(", 0, ")"],
            "fields": [],
            "compile_str": "",
            "significant": "true",
            "date": "1",
            "type": {"value": this.d_project.column_list[i].index, "label": this.d_project.column_list[i].name }
          };
          new_query_data.fields.push(new_field_data);
          rule_data.query_list.push(new_query_data);
          query_compile_str_array.push(rule_data.query_list.length - 1);
          query_compile_str_array.push('and');
          any_rule_columns_filled = true;
        } else {
          if (['select', 'radiogroup'].indexOf(this.d_project.column_list[i].param_type) !== -1) {
            if (['=', '<>'].indexOf(this.d_project.column_list[i].operation.value) !== -1) {
              if (this.d_project.column_list[i].selected_items && this.d_project.column_list[i].selected_items.length > 0) {
                let values_list = [];
                for (let op_index in this.d_project.column_list[i].selected_items) {
                  values_list.push(this.d_project.column_list[i].selected_items[op_index].value);
                }
                let new_field_data = {
                    "loc": [
                      {"label": "Genel", "value": "general", "type": "group"},
                      {"label": this.d_project.column_list[i].name, "value": this.d_project.column_list[i].index, "type": this.d_project.column_list[i].param_type}
                    ],
                    "using_data_if_not_reaching_record_data": "true",
                    "val_list": this.d_project.column_list[i].selected_items,
                    "values": values_list,                    
                    "significant": "true",
                    "value_type": this.d_project.column_list[i].param_type,
                    "operation": this.d_project.column_list[i].operation.value
                };
                let new_query_data = {
                  "time_of_value": "any_value",
                  "data_type": "old_recorded_data",
                  "compile_str_array": ["(", 0, ")"],
                  "fields": [],
                  "compile_str": "",
                  "significant": "true",
                  "date": "1",
                  "type": {"value": this.d_project.column_list[i].index, "label": this.d_project.column_list[i].name }
                };
                new_query_data.fields.push(new_field_data);
                rule_data.query_list.push(new_query_data);
                query_compile_str_array.push(rule_data.query_list.length - 1);
                query_compile_str_array.push('and');
                any_rule_columns_filled = true;
              }
            }
          } else {
            if (this.d_project.column_list[i].selected) {
              let values_list = [];
              values_list.push(this.d_project.column_list[i].selected);
              let new_field_data = {
                  "loc": [
                    {"label": "Genel", "value": "general", "type": "group"},
                    {"label": this.d_project.column_list[i].name, "value": this.d_project.column_list[i].index, "type": this.d_project.column_list[i].param_type}
                  ],
                  "using_data_if_not_reaching_record_data": "true",
                  "val_list": values_list,
                  "values": values_list,
                  "significant": "true",
                  "value_type": this.d_project.column_list[i].param_type,
                  "operation": this.d_project.column_list[i].operation.value
              };
              let new_query_data = {
                "time_of_value": "any_value",
                "data_type": "old_recorded_data",
                "compile_str_array": ["(", 0, ")"],
                "fields": [],
                "compile_str": "",
                "significant": "true",
                "date": "1",
                "type": {"value": this.d_project.column_list[i].index, "label": this.d_project.column_list[i].name }
              };
              new_query_data.fields.push(new_field_data);
              rule_data.query_list.push(new_query_data);
              query_compile_str_array.push(rule_data.query_list.length - 1);
              query_compile_str_array.push('and');
              any_rule_columns_filled = true;
            }
          }
        }
      }
      if (any_rule_columns_filled) {
        query_compile_str_array.push(')');
        rule_data.compile_str_array = query_compile_str_array;
      }
      return rule_data;
    },
    f_wanalyzerProjectDatasetGet: function () {
      if (this.p_datasetView === 'yes') {
        this.d_datasetWTable = '';
        this.d_datasetViewLoading = true;
        let query = 'project_id=' + this.d_projectId ;
        let data = {
          'pagination': '',
          'filter_rule': this.f_createRuleAlgorithm(),
          'wai_columns': 'yes'
        };
        WanalyzerService.wanalyzer_project_dataset_get(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              this.d_datasetWTable = resp.data.wtable;
              this.d_datasetViewLoading = false;
              // this.f_datasetShowRefresh();
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_deleteSelectedItem: function (col_index, selected_item_ind) {
      this.d_project.column_list[col_index].selected_items.splice(selected_item_ind, 1);
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_wanalyzerServicesTrigger: function () {
      this.f_wanalyzerProjectStatisticsAnalyzeList();
      this.f_wanalyzerProjectDatasetGet();
    },
    f_selectOperationForColumn: function (col_index, operation) {
      this.d_project.column_list[col_index].operation = operation;
      // null yönetiminde değerlerin seçili olması gerekmiyor.
      if (['is_null', 'is_not_null'].indexOf(operation.value) !== -1) {
        if (this.d_project.column_list[col_index].selected_items && this.d_project.column_list[col_index].selected_items.length > 0) {
          this.d_project.column_list[col_index].selected_items = [];
        }
        if (this.d_project.column_list[col_index].selected) {
          this.d_project.column_list[col_index].selected = '';
        }
      }
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_resetProjectData: function () {
      this.d_project = JSON.parse(JSON.stringify(this.d_resetProject));
      this.$forceUpdate();
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_statsShowRefresh: function () {
      this.d_statsShow = false;
      setTimeout(function () {
        this.d_statsShow = true;
        this.$forceUpdate();
      }.bind(this), 1);
    },
    f_datasetShowRefresh: function () {
      this.d_datasetShow = false;
      setTimeout(function () {
        this.d_datasetShow = true;
        this.$forceUpdate();
      }.bind(this), 1);
    },
    f_wanalyzerProjectStatisticsAnalyzeList: function () {
      this.d_statsViewLoading = true;
      // this.d_statisticResults = {};
      let stats_data = {
        'rule_algoritm': '',
        'data': {
          'general': {
            'analyze_type': {'val': {'value': 'detailed_descriptive_stats'}},
            'column_selection_type': {'val': {'value': 'manuel_column_selection'}},
            'selected_columns': {},
            'descriptive_stats': {
              'mean': {'name': {'value': 'mean'}, 'detail': {}},
              'frequency': {'name': {'value': 'frequency'}, 'detail': {}},
            }
          }
        }
      };
      for (let i in this.d_project.column_list) {
        if (this.d_project.column_list[i].wai_output) {
          stats_data['data']['general']['selected_columns'][this.d_project.column_list[i].index] = {
            'name': {'value': this.d_project.column_list[i].index, 'label': this.d_project.column_list[i].name},
            'detail': {
                "param_type_usage": {"val": {"value": "auto"} },
                "option_group_type": {"val": {"value": "standard_mode"} },
                "selected_column_timeline_mode": {"val": {"value": "no"} }
            }
          }
        }
      } 
      stats_data.rule_algoritm = this.f_createRuleAlgorithm()
      let query = 'project_id=' + this.d_projectId;
      let data = {
        'list': []
      };
      data.list.push(stats_data);
      // console.log(data);
      WanalyzerService.wanalyzer_project_statistics_analyze_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_statisticResults = resp.data.statistic_results;
            this.d_statsViewLoading = false;
            if (this.p_datasetView !== 'yes') {
              for (let i in this.d_statisticResults) {
                for (let k in this.d_statisticResults[i]) {
                  for (let a in this.d_statisticResults[i][k].analyze_list) {
                    this.d_selectedTabItemId = 'analyze_' + i + '_' + k + '_' + a;
                    break
                  }
                  break
                }
                break
              }
            }
            this.f_statsShowRefresh();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_addToColumnSelectedItems: function (col_ind, opt) {
      if (opt) {
        if (!this.d_project.column_list[col_ind].selected_items) {
          this.d_project.column_list[col_ind].selected_items = [];
        }
        let det = false;
        for (let i in this.d_project.column_list[col_ind].selected_items) {
          if (this.d_project.column_list[col_ind].selected_items[i].value === opt.value) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.d_project.column_list[col_ind].selected_items.push(opt);
        }
      } else {
        this.d_project.column_list[col_ind].selected_items.splice(0, this.d_project.column_list[col_ind].selected_items.length);
      }
      if (this.d_autoLoading) {
        this.f_wanalyzerServicesTrigger();
      }
    },
    f_wanalyzerProjectDetailsColumnsWaiGet: function () {
      this.d_projectColumnListLoading = true;
      let query = 'project_id=' + this.d_projectId ;
      let data = {};
      WanalyzerService.wanalyzer_project_details_columns_wai_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_projectColumnListLoading = false;
            this.d_project.details = resp.data.details;
            for (let i in resp.data.column_list) {
              if (!this.d_selectedColumn) {
               if (resp.data.column_list[i].wai_output) {
                this.d_selectedColumn = resp.data.column_list[i].id;
               } 
              }
              resp.data.column_list[i].operation = {
                'value': '=',
                'translation': {
                  'tr': 'eşittir',
                  'en': 'equal to'
                }
              };
              if (resp.data.column_list[i].param_type === 'select' || resp.data.column_list[i].param_type === 'radiogroup') {
                resp.data.column_list[i].selected_items = [];
              } else if (resp.data.column_list[i].param_type === 'integer' || resp.data.column_list[i].param_type === 'float') {
                resp.data.column_list[i].selected = '';
              }
            }
            this.d_project.column_list = resp.data.column_list;
            for (let i in this.d_project.column_list) {
              if (this.d_project.column_list[i].wai_input === 1) {
                  this.d_isThereInputColumn = true;
                  break;
              }
            }
            this.d_resetProject = JSON.parse(JSON.stringify(this.d_project));
            if (this.d_autoLoading) {
              this.f_wanalyzerServicesTrigger();
            }
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerProjectStatsResultList: function () {
      this.d_statsList.analyze_list = [];
      let query = 'project_id=' + this.d_projectId ;
      query += '&stats_id=' + this.d_statsId ;
      let data = {
        'selected_columns': this.d_filterData.selected_columns,
        'selected_stats': this.d_filterData.selected_stats,
        'selected_p_value': this.d_filterData.selected_p_value,
        'selected_analyze_types': this.d_filterData.selected_analyze_types,
        'user_score_list': this.d_filterData.user_score_list,
        'pagination': this.d_filterData.pagination,
      };
      WanalyzerService.wanalyzer_project_stats_result_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_statsList.analyze_list = resp.data.analyze_list;
            this.d_statsList.all_count = resp.data.all_count;
            this.d_statsList.filter_stats = resp.data.filter_stats;
            this.d_statsList.filter_analyze_types = resp.data.filter_analyze_types;
            this.d_statsList.selected_columns = resp.data.selected_columns;
            this.d_statsList.stats_name = resp.data.stats_name;
            this.d_statsList.stats_info = resp.data.stats_info;
            this.d_statsList.last_update_time_of_project_cases = resp.data.last_update_time_of_project_cases;
            this.d_filterData.pagination.all_count = resp.data.all_count;
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_calculateChartWidth: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 800;
        } else if (chart.labels.length > 10) {
          return 1000;
        } else {
          return 500;
        }
      } else {
        return 500;
      }
    },
    f_showChartData: function (chart_data) {
      let new_chart_data = {
        backgroundColor: "#fff",
        labels: chart_data.labels ? chart_data.labels : [],
        datasets: []
      };
      for (let d in chart_data.datasets) {
        let new_dataset = {}
        if (chart_data.datasets[d].label !== undefined) {
          new_dataset.label = chart_data.datasets[d].label;
        }
        if (chart_data.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart_data.datasets[d].lineTension;
        }
        if (chart_data.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart_data.datasets[d].borderColor;
        }
        if (chart_data.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart_data.datasets[d].backgroundColor;
        }
        if (chart_data.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart_data.datasets[d].showLine;
        }
        if (chart_data.datasets[d].data !== undefined) {
          new_dataset.data = chart_data.datasets[d].data;
        }
        new_chart_data.datasets.push(new_dataset);
      }
      this.chart_data = JSON.parse(JSON.stringify(new_chart_data));
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_data.type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_calculateChartHeight: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 400;
        } else if (chart.labels.length > 10) {
          return 500;
        } else {
          return 250;
        }
      } else {
        return 250;
      }
    },
    f_calculateChartStyle: function (chart) {
      let style = '';
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:600px; width:1200px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:800px; width:1600px;';
          } else {
            return 'position: relative; height:400px; width:800px;';
          }
        } else if (chart.labels.length > 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:700px; width:1400px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:900px; width:1800px;';
          } else {
            return 'position: relative; height:500px; width:1000px;';
          }
        } else {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:450px; width:900px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:650px; width:1300px;';
          } else {
            return 'position: relative; height:250px; width:500px;';
          }
        }
      } else {
        return 'position: relative; height:250px; width:500px;';
      }
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8; font-size: 12px;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold; font-size: 10px;';
      }
      /*
        if (r_index === 0) {
          style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
        }
        if (c_index === 0) {
          style += 'position: sticky; left: 0;';
        }
      */
      return style;
    },
    f_tableShow: function () {
      this.d_tableShow = false;
      setTimeout(function () {
        this.d_tableShow = true;
        this.$forceUpdate();
      }.bind(this), 1000);
    },
    f_calculateTableStyle: function () {
      let style = '';
      if (this.d_fullScreen) {
        style = 'overflow-x: auto;';
      } else {
        style = 'overflow-x: auto;';
      }
      return style;
    },
    f_searchCrossRows: function (row, row_index, rows_length) {
      // if (this.d_searchTextRow && row_index !== 0 && row_index !== rows_length - 1) {
      if (this.d_searchTextRow && row_index !== 0) {
        let text_list = this.d_searchTextRow.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (row[0].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
      } else {
        if (row_index >= this.d_tablePagination.start && row_index <= this.d_tablePagination.end) {
          return true;
        }
      }
      return false;
    },
    f_searchCrossColumns: function (col_index, first_row, col_length) {
      // if (this.d_searchTextColumn && col_index !== 0 && col_index !== col_length - 1) {
      if (this.d_searchTextColumn && col_index !== 0) {
        let text_list = this.d_searchTextColumn.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (first_row[col_index].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD hh:mm");
            return x;
          }
        }
      } else {
        return "";
      }
    },
    f_controlWaiOutputMode: function (col, output_show_type) {
      let show_outpul_col = false;
      if (col.wai_output === 1) {
        if (output_show_type === 'right') {
          if (col.style === undefined || (col.style && (col.style.waiLocation === undefined || col.style.waiLocation === 'right_any_location'))) {
            show_outpul_col = true;
          }
        } else if (output_show_type === 'bottom') {
          if (col.style && col.style.waiLocation === 'bottom_as_card') {
            show_outpul_col = true;
          }
        }
      }
      return show_outpul_col;
    },
    f_showTextAsModal: function (text) {
      this.d_selectedTableCellFullText = text.toString();
      this.d_showTableCellFullText = true;
    },
    f_calculateColumnStyle: function (column_data) {
      let column_style = 'width: 100%; padding: 3px;';
      // let column_style = 'width: 100%; padding: 3px; text-align: center;';
      // console.log('column_data.style : ', column_data.style);
      if (column_data.style && column_data.style.backgroundColor) {
        column_style += 'background: ' + column_data.style.backgroundColor + ';';
      }
      if (column_data.style && column_data.style.color) {
        column_style += 'color: ' + column_data.style.color + ';';
      }
      if (column_data.style && column_data.style.fontSize) {
        column_style += 'font-size: ' + column_data.style.fontSize + 'px;';
      }
      return column_style;
    },
    f_resetForm: function () {
      this.d_clientId = '';
      this.d_wdmrId = '';
      this.d_project.details = '';
      this.d_project.column_list = [];
      this.f_wanalyzerProjectStatsResultList();
    },
    f_wanalyzerProjectCellCalculate: function () {
      let query = 'project_id=' + this.d_projectId;
      if (this.d_clientId) {
        query += '&client_id=' + this.d_clientId
      }
      if (this.d_wdmrId) {
        query += '&wdmr_id=' + this.d_wdmrId
      }
      let data = {
        'column_list': [],
        'sql_name': this.d_sqlName
      };
      let not_filled_det = false;
      for (let i in this.d_project.column_list) {
        if (this.d_project.column_list[i].wai_input === 1) {
          if (this.d_project.column_list[i].val) {
            let new_input_data = {
              'id': this.d_project.column_list[i].id,
              'label': this.d_project.column_list[i].name,
              'val': this.d_project.column_list[i].val ? this.d_project.column_list[i].val : ''
            }
            data.column_list.push(new_input_data);
          } else {
            if (this.d_clientId || this.d_wdmrId) {
              //
            } else {
              not_filled_det = true;
              break            
            }
          }
        }
      }
      if (not_filled_det) {
        alert(this.StoreLangTranslation.data['please_fill_all_inputs'][this.StoreLang]);
      } else {
        for (let cl_index in this.d_project.column_list) {
          if (this.d_project.column_list[cl_index].wai_output === 1) {
            this.d_project.column_list[cl_index].obj = '';
          }
        }
        this.d_analyzeContinuing = true;
        WanalyzerService.wanalyzer_project_cell_calculate(query, data)
          .then(resp => {
            this.d_analyzeContinuing = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              for (let col_index in resp.data.column_list) {
                if (resp.data.column_list[col_index].wai_output === 1) {
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                      this.d_project.column_list[cl_index].obj = resp.data.column_list[col_index].obj;
                      if (resp.data.column_list[col_index].style !== undefined) {
                        this.d_project.column_list[cl_index].style = resp.data.column_list[col_index].style;
                      }
                      break;
                    }
                  }
                } else if (resp.data.column_list[col_index].wai_input === 1) {
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                      if (!this.d_project.column_list[cl_index].val) {
                        let input_val = '';
                        for (let k in resp.data.column_list[col_index].obj) {
                          for (let l_index in resp.data.column_list[col_index].obj[k].list) {
                            input_val = resp.data.column_list[col_index].obj[k].list[l_index].val;
                            break;
                          }
                          if (input_val) {
                            break;
                          }
                        }
                        this.d_project.column_list[cl_index].val = input_val;
                      }
                      break;
                    }
                  }                  
                }
              }
              let time_based_list = [];
              for (let i in this.d_project.column_list) {
                if (this.d_project.column_list[i].wai_output === 1) {
                  if (this.d_project.column_list[i].obj) {
                    for (let obj_key in this.d_project.column_list[i].obj) {
                      let date_string = '';
                      if (this.d_project.column_list[i].obj[obj_key].date) {
                        date_string = this.d_project.column_list[i].obj[obj_key].date;
                      }
                      for (let obj_li_index in this.d_project.column_list[i].obj[obj_key].list) {
                        let obj_data = this.d_project.column_list[i].obj[obj_key].list[obj_li_index];
                        let new_val_item = {
                          'date': date_string,
                          'val': obj_data['val'],
                          'label': this.d_project.column_list[i].name,
                          'background_color': ''
                        };
                        if (this.d_project.column_list[i].style && this.d_project.column_list[i].style.backgroundColor) {
                          new_val_item.background_color = this.d_project.column_list[i].style.backgroundColor;
                        }
                        time_based_list.push(new_val_item);
                      }
                    }
                  }
                }
              }
              let param = 'date';
              let asc_or_desc = 1;
              // let asc_or_desc = -1;
              function compare(a, b) {
                // Use toUpperCase() to ignore character casing
                let param_a = '';
                let param_b = '';
                try {
                  param_a = a[param].toLocaleLowerCase();
                  param_b = b[param].toLocaleLowerCase();
                } catch (err) {
                  param_a = a[param];
                  param_b = b[param];
                }

                let comparison = 0;
                if (param_a > param_b) {
                  comparison = 1;
                } else if (param_a < param_b) {
                  comparison = -1;
                }
                return comparison * asc_or_desc;
              }
              time_based_list.sort(compare);
              // console.log('time_based_list : ', time_based_list);
              if (time_based_list.length > 0) {
                this.d_timeLineLeftToRight = [];
                // first row is year-month-day based.
                // [[{}], [{}], [{"date": "2021-12-6"}], [{"date": "2021-12-7"}], [{"date": "2021-12-16"}], [{"date": "2021-12-27"}], [{"date": "2022-1-4"}] ]
                let first_row = [[{}], [{}]];
                let unique_date_list = [];
                let unique_column_label_list = [];
                for (let t_index in time_based_list) {
                  if (unique_column_label_list.indexOf(time_based_list[t_index].label) === -1) {
                    unique_column_label_list.push(time_based_list[t_index].label);
                  }
                  let year_month_day = '';
                  if (time_based_list[t_index].date) {
                    if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                      year_month_day = time_based_list[t_index].date.split(' ')[0];
                    } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                      year_month_day = time_based_list[t_index].date.split('T')[0];
                    }
                  }
                  if (year_month_day && unique_date_list.indexOf(year_month_day) === -1) {
                    unique_date_list.push(year_month_day);
                  }
                }
                // console.log('unique_date_list : ', unique_date_list);
                for (let unique_ind in unique_date_list) {
                  first_row.push([{'date': unique_date_list[unique_ind]}]);
                }
                this.d_timeLineLeftToRight.push(first_row);
                for (let unique_ind in unique_column_label_list) {
                  let first_col = {
                    "background_color": "#e5f7f3",
                    "value": unique_column_label_list[unique_ind],
                    "label": unique_column_label_list[unique_ind]
                  };
                  for (let cl_index in this.d_project.column_list) {
                    if (this.d_project.column_list[cl_index].name === unique_column_label_list[unique_ind]) {
                      if (this.d_project.column_list[cl_index].style && this.d_project.column_list[cl_index].style.backgroundColor) {
                        first_col.background_color = this.d_project.column_list[cl_index].style.backgroundColor;
                      }
                      break;
                    }
                  }
                  let new_row = [[first_col], []];
                  for (let i = 0; i < unique_date_list.length; i++) {
                    new_row.push([]);
                  }
                  this.d_timeLineLeftToRight.push(new_row);
                }
              }
              for (let row_index in this.d_timeLineLeftToRight) {
                if (row_index > 0) {
                  let row_label = this.d_timeLineLeftToRight[row_index][0][0]['label'];
                  for (let col_index in this.d_timeLineLeftToRight[row_index]) {
                    if (parseInt(col_index) === 1) {
                      for (let t_index in time_based_list) {
                        if (!time_based_list[t_index].date) {
                          let cell_value = '';
                          let cell_label = '';
                          if (typeof(time_based_list[t_index].val) === 'object') {
                            cell_value = time_based_list[t_index].val.value;
                            cell_label = time_based_list[t_index].val.label;
                          } else {
                            cell_value = time_based_list[t_index].val;
                            cell_label = time_based_list[t_index].val;                              
                          }
                          if (row_label === time_based_list[t_index].label) {
                            let new_cell_item = {
                              'label': cell_label,
                              'value': cell_value,
                              'date': '',
                              'background_color': time_based_list[t_index].background_color
                            };
                            this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                          }
                        }
                      }
                    } else if (parseInt(col_index) > 1) {
                      let col_date = this.d_timeLineLeftToRight[0][col_index][0]['date'];
                      // row_label ve col_date olan verileri time_based_list içerisinden ekleyeceğiz.
                      for (let t_index in time_based_list) {
                        let year_month_day = '';
                        if (time_based_list[t_index].date) {
                          if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                            year_month_day = time_based_list[t_index].date.split(' ')[0];
                          } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                            year_month_day = time_based_list[t_index].date.split('T')[0];
                          }
                        }
                        if (year_month_day) {
                          if (col_date === year_month_day) {
                            let cell_value = '';
                            let cell_label = '';
                            if (typeof(time_based_list[t_index].val) === 'object') {
                              cell_value = time_based_list[t_index].val.value;
                              cell_label = time_based_list[t_index].val.label;
                            } else {
                              cell_value = time_based_list[t_index].val;
                              cell_label = time_based_list[t_index].val;                              
                            }
                            if (row_label === time_based_list[t_index].label) {
                              let new_cell_item = {
                                'label': cell_label,
                                'value': cell_value,
                                'date': time_based_list[t_index].date,
                                'background_color': time_based_list[t_index].background_color
                              };
                              this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              // console.log('this.d_timeLineLeftToRight : ', this.d_timeLineLeftToRight);

              for (let i in this.d_project.column_list) {
                if (this.d_project.column_list[i].obj) {
                  let obj_item_list = [];
                  for (let obj_key in this.d_project.column_list[i].obj) {
                    let obj_date = '';
                    if (this.d_project.column_list[i].obj[obj_key].date) {
                      obj_date = this.d_project.column_list[i].obj[obj_key].date;
                    }
                    let new_obj_item = {
                      'date': obj_date,
                      'key': obj_key
                    };
                    obj_item_list.push(new_obj_item);
                  }
                  let param = 'date';
                  let asc_or_desc = 1;
                  // let asc_or_desc = -1;
                  function compare(a, b) {
                    // Use toUpperCase() to ignore character casing
                    let param_a = '';
                    let param_b = '';
                    try {
                      param_a = a[param].toLocaleLowerCase();
                      param_b = b[param].toLocaleLowerCase();
                    } catch (err) {
                      param_a = a[param];
                      param_b = b[param];
                    }

                    let comparison = 0;
                    if (param_a > param_b) {
                      comparison = 1;
                    } else if (param_a < param_b) {
                      comparison = -1;
                    }
                    return comparison * asc_or_desc;
                  }
                  obj_item_list.sort(compare);
                  let new_obj_order = [];
                  for (let obj_ind in obj_item_list) {
                    new_obj_order.push(obj_item_list[obj_ind].key);
                  }
                  this.d_project.column_list[i].obj_order = new_obj_order;
                }
              }

              this.$forceUpdate();
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
  },
  watch: {
    'd_filterData.pagination.current': function () {
      // console.log("d_filterData.pagination", this.d_filterData.pagination);
      this.d_filterData.pagination.start = (this.d_filterData.pagination.current - 1) * this.d_filterData.pagination.perpage;
      this.d_filterData.pagination.end = (this.d_filterData.pagination.perpage * this.d_filterData.pagination.current);
      this.f_wanalyzerProjectStatsResultList();
    },
    'd_tablePagination.current': function () {
      this.d_tablePagination.start = (this.d_tablePagination.current - 1) * this.d_tablePagination.perpage;
      this.d_tablePagination.end = (this.d_tablePagination.perpage * this.d_tablePagination.current) - 1;
      this.f_tableShow();
    },
    'd_clientId': function () {
      // this.f_resetForm();
    }
  }
}

</script>

