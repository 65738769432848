import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  report_list (query='') {
    if (query) {
      query = '?' + query;
    }
    return Vue.http.post(API_BASE_URL + 'v1/report/list' + query);
  },
  report_dashboard_list (query='') {
    if (query) {
      query = '?' + query;
    }
    return Vue.http.post(API_BASE_URL + 'v1/report/dashboard/list' + query);
  },
  report_details (query='') {
    if (query) {
      query = '?' + query;
    }
    return Vue.http.post(API_BASE_URL + 'v1/report/details' + query);
  },
  table_to_excel(data) {
    return Vue.http.post(API_BASE_URL + 'v1/table/to/excel', data);
  },
  report_view_record(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/report/record/view?' + query, data);
  },
};
