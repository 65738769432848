<template>
  <div :class="[d_mainFullScreen ? 'full-screen-mode' : 'normal-mode']" style="background: white;">
    <b-card-header class="p-1">
      <b-row>
        <b-col cols="4">
          <div style="float: left;">
            <img src="@/icon/2933568.png" style="width: 2em; margin-right: 5px; padding: 0px;" />
            <span v-if="p_tableDetails.name" :title="p_tableDetails.name"> {{ p_tableDetails.name.length > 30 ? p_tableDetails.name.substring(0,30) + '...' : p_tableDetails.name }} </span>
            <small>({{ f_dateFormat(p_tableDetails.ud) }})</small>
            <template v-if="p_tableDetails.sand_watch"> <img src="@/icon/sandwatch.gif" style="width: 2em;" /> </template>
          </div>
        </b-col>
        <b-col cols="3">
          <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="p_tableDetails.pagination.current" :total-rows="p_tableDetails.table.length" :per-page="p_tableDetails.pagination.perpage"></b-pagination>
        </b-col>
        <b-col cols="2">
          Satır Adedi: {{ p_tableDetails.filtered_count }} / {{ p_tableDetails.count }}
        </b-col>
        <b-col cols="1" style="text-align: center;">
          <strong style="color: #50afc9; text-align: right" title="Dataset"> Dataset <img src="@/icon/2393061.png" style="width: 2em;" /> </strong>
        </b-col>
        <b-col cols="2">
          <b-button class="pull-right" size="md" :variant="d_mainFullScreen ? 'warning' : 'white'" :style="d_mainFullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_mainFullScreen ? d_mainFullScreen = false : d_mainFullScreen = true">
            <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
          </b-button>
          <b-button class="pull-right" size="md" variant="white" style="margin-right: 5px; padding: 0px;" @click="f_convertFilteredTableToExcel()">
            <img src="@/icon/201295.png" title="Excel olarak indir" style="width: 2em;" /> indir
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <div id="layer_detailed" class="general_scroll_class" :style="f_calculateLayerDetailsTableStyle()">
      <table border="1" align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
        <thead>
          <tr style="border-bottom: solid 1px #c1c7cc;">
            <th></th>
            <template v-for="(caption, caption_ind) in p_tableDetails.table_header">
              <th v-if="p_tableDetails.show_columns.length === 0 || p_tableDetails.show_columns.indexOf(caption_ind) !== -1" style="position: sticky; z-index: 1000; top: 0; background-color: lightblue;">
                <div> {{ caption.label }} </div>
                <div style="text-align: right;">
                  <b-button variant="white" style="padding: 0px;" @click="f_captionOptions(caption_ind, caption)">
                    <img src="@/icon/3094236.png" style="width: 1.5em; margin-left: 5px; padding: 0px;" />
                  </b-button>
                  <b-button v-if="caption.selected_options.length > 0" variant="white" style="padding: 0px;" @click="f_clearFilterColumn(caption_ind, caption)">
                    <img src="@/icon/1517831.png" style="width: 1.5em; margin-left: 5px; padding: 0px;" />
                  </b-button>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, row_index) in p_tableDetails.filtered_table">
            <tr style="border-bottom: solid 1px #c1c7cc;">
              <td>{{ row_index + 1 }}</td>
              <template v-for="(col, col_index) in row">
                <td v-if="p_tableDetails.show_columns.length === 0 || p_tableDetails.show_columns.indexOf(col_index) !== -1" :style="f_calculateTableTdStyle(col, col_index, row_index)">
                  <template v-if="col.val && col.val.label">
                    <template v-if="['date', 'datetime'].indexOf(p_tableDetails.table_header[col_index].type) !== -1">
                      {{ f_dateFormat(col.val.label) }}
                    </template>
                    <template v-else>
                      <div :title="col.val.label"> {{ col.val.label.length > 30 ? col.val.label.substring(0,30) + '..' : col.val.label }} </div>
                    </template>
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-modal v-if="d_captionOptions.show" :title="d_captionOptions.caption_data.label" centered class="modal-success" v-model="d_captionOptions.show" @ok="d_captionOptions.show = false" ok-variant="success" hide-footer size="xl">
      <b-card-header class="p-1">
        <b-row v-if="d_captionOptions.caption_data.total_count !== undefined">
          <b-col cols="6">Toplam Adet</b-col>
          <b-col cols="6">
            {{ d_captionOptions.caption_data.total_count }}
          </b-col>
        </b-row>
      </b-card-header>
      <b-row>
        <b-col cols="6">
          <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_captionOptions.pagination.current" :total-rows="d_captionOptions.caption_data.option_order.length" :per-page="d_captionOptions.pagination.perpage"></b-pagination>
        </b-col>
        <b-col cols="6">
          <b-form-input id="option_filter" v-model="d_captionOptions.search_text" placeholder="Filtrelemek için yazınız."></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">
            <b-form-checkbox-group stacked v-model="d_captionOptions.selected_options">
              <template v-for="(op, op_ind) in d_captionOptions.filtered_options">
                <b-form-checkbox v-if="op && op.label" :value="op.label" :style="op.style"> {{ op.label }} (adet = {{ op.count }}) </b-form-checkbox>
              </template>
            </b-form-checkbox-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="warning" @click="d_captionOptions.show = false" style="margin-right: 10px;">iptal</b-button>
          <b-button variant="success" @click="f_applyFilterForColumns()" style="margin-right: 10px;">Filtre Uygula</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import ReportService from '@/services/report';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WTable2',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_tableDetails: {
      type: Object,
      required: true
    },
  },
  components: {},
  data () {
    return {
      d_defaultPagination: {
        'start': 0,
        'end': 15,
        'current': 1,
        'perpage': 15,
        'all_count': 0
      },
      d_mainFullScreen: false,
      d_captionOptions: {
        'pagination': {
          'start': 0,
          'end': 15,
          'current': 1,
          'perpage': 15,
          'all_count': 0
        },
        'search_index_list': [],
        'selected_options': [],
        'filtered_options': [],
        'timeout': 0,
        'search_text': '',
        'new_value': '',
        'caption_data': '',
        'show': false,
        'caption_index': ''
      },
    };
  },
  created: function () {
    if (this.p_tableDetails && this.p_tableDetails.pagination) {
      this.d_defaultPagination = this.p_tableDetails.pagination;
      this.d_defaultPagination.start = 0;
      this.d_defaultPagination.current = 1;
    }
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_filterTable();

  },
  methods: {
    f_convertFilteredTableToExcel: function () {
      let data = {};
      data.table_header = this.p_tableDetails.table_header;
      data.table = this.p_tableDetails.table;
      ReportService.table_to_excel(data)
        .then(resp => {
          if (resp.data.file) {
            this.f_downloadDocument(resp.data.file, resp.data.file_name)
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_downloadDocument: function (base64_file, base64_file_name) {
      // console.log("base64_file ", base64_file);
      // console.log("base64_file_name ", base64_file_name);
      function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
      }

      var saveByteArray = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, name) {
          var blob = new Blob(data, { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
        };
      }());
      var sampleBytes = base64ToArrayBuffer(base64_file);
      saveByteArray([sampleBytes], base64_file_name);
    },
    f_dateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') !== -1) {
          return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY HH:mm");
        } else {
          return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_filterTable: function () {
      this.p_tableDetails.filtered_count = 0;
      this.p_tableDetails.filtered_table = [];
      let is_there_selected_header_option = false;
      for (let header_op_index in this.p_tableDetails.table_header) {
        let table_header_col = this.p_tableDetails.table_header[header_op_index];
        if (table_header_col.selected_options && table_header_col.selected_options.length > 0) {
          is_there_selected_header_option = true;
          break;
        }
      }
      // console.log('is_there_selected_header_option : ', is_there_selected_header_option);
      if (!is_there_selected_header_option) {
        this.p_tableDetails.filtered_count = this.p_tableDetails.table.length;
        for (let i = this.p_tableDetails.pagination.start; i < this.p_tableDetails.pagination.end; i++) {
          if (i < this.p_tableDetails.table.length) {
            this.p_tableDetails.filtered_table.push(this.p_tableDetails.table[i]);
          }
        }
      } else {
        let true_selected_count = 0;
        for (let row_index in this.p_tableDetails.table) {
          let det = true;
          for (let caption_index in this.p_tableDetails.table_header) {
            let caption_cell = this.p_tableDetails.table_header[caption_index];
            let active_cell = this.p_tableDetails.table[row_index][caption_index];
            if (caption_cell.selected_options.length > 0) {
              if (active_cell.val && active_cell.val.label) {
                if (caption_cell.selected_options.indexOf(active_cell.val.label) === -1) {
                  det = false;
                  break;
                }
              } else {
                det = false;
                break;
              }
            }
          }
          if (det) {
            this.p_tableDetails.filtered_count += 1;
            true_selected_count += 1;
            if (true_selected_count >= this.p_tableDetails.pagination.start && true_selected_count < this.p_tableDetails.pagination.end) {
              this.p_tableDetails.filtered_table.push(this.p_tableDetails.table[row_index]);
            } else {
              if (true_selected_count >= this.p_tableDetails.pagination.end) {
                // break;
              }
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_filterOptions: function () {
      this.d_captionOptions.filtered_options = [];
      if (this.d_captionOptions.search_text) {
        for (let i in this.d_captionOptions.caption_data.option_order) {
          let option_label = this.d_captionOptions.caption_data.option_order[i];
          let option_data = this.d_captionOptions.caption_data.options[option_label];
          if (option_data.label && option_data.label.toLocaleLowerCase().indexOf(this.d_captionOptions.search_text.toLocaleLowerCase()) !== -1) {
            this.d_captionOptions.filtered_options.push(option_data);
          }
        }
      } else {
        for (let i = this.d_captionOptions.pagination.start; i < this.d_captionOptions.pagination.end; i++) {
          if (i < this.d_captionOptions.caption_data.option_order.length) {
            let option_label = this.d_captionOptions.caption_data.option_order[i];
            let option_data = this.d_captionOptions.caption_data.options[option_label];
            this.d_captionOptions.filtered_options.push(option_data);
          }
        }
      }
      this.$forceUpdate();
    },
    f_clearFilterColumn: function (caption_index) {
      this.p_tableDetails.table_header[caption_index].selected_options = [];
      this.f_filterTable();
    },
    f_clearAllFilterColumns: function (caption_index) {
      for (let caption_index in this.p_tableDetails.table_header) {
        this.p_tableDetails.table_header[caption_index].selected_options = [];
      }
      this.f_filterTable();
    },
    f_applyFilterForColumns: function () {
      this.p_tableDetails.pagination = this.d_defaultPagination;
      this.p_tableDetails.table_header[this.d_captionOptions.caption_index].selected_options = this.d_captionOptions.selected_options;
      this.f_filterTable();
      this.d_captionOptions.show = false;
      this.$forceUpdate();
    },
    f_captionOptions: function (caption_index, caption_data) {
      this.d_captionOptions.selected_options = JSON.parse(JSON.stringify(caption_data.selected_options));
      // this.d_captionOptions.search_text = '';
      this.d_captionOptions.caption_index = caption_index;
      this.d_captionOptions.caption_data = JSON.parse(JSON.stringify(caption_data));
      this.f_filterOptions();
      this.d_captionOptions.show = true;
      setTimeout(function () {
        let div_el = document.getElementById('option_filter');
        div_el.focus();
      }.bind(this), 500);
    },
    f_calculateLayerDetailsTableStyle: function () {
      let style = 'overflow-x: auto; overflow-y: auto;';
      let height = 'height: ' + (window.innerHeight - 200).toString() + 'px;';
      style += height;
      return style;
    },
    f_calculateGeneralStyle: function () {
      let style = '';
      let height = 'height: ' + (window.innerHeight - 50).toString() + 'px;';
      style += height;
      return style;
    },
    f_calculateTableTdStyle: function (col, col_index, row_index) {
      let style = '';
      if (col.val && col.val.label && this.p_tableDetails.table_header[col_index].options[col.val.label] && this.p_tableDetails.table_header[col_index].options[col.val.label].style) {
        style += this.p_tableDetails.table_header[col_index].options[col.val.label].style;
      }
      return style;
    },
  },
  watch: {
    'd_layerDetails.updated': function () {
      this.$forceUpdate();
    },
    'd_captionOptions.search_text': function () {
      this.d_captionOptions.pagination = {
        'start': 0,
        'end': 15,
        'current': 1,
        'perpage': 15,
        'all_count': 0
      };
      if (this.d_captionOptions.timeout) {
        clearTimeout(this.d_captionOptions.timeout);
        this.d_captionOptions.timeout = 0;
      }
      this.d_captionOptions.timeout = setTimeout(function () {
        this.f_filterOptions();
      }.bind(this), 1000);
    },
    'p_tableDetails.pagination.current': function () {
      this.p_tableDetails.pagination.start = (this.p_tableDetails.pagination.current - 1) * this.p_tableDetails.pagination.perpage;
      this.p_tableDetails.pagination.end = (this.p_tableDetails.pagination.perpage * this.p_tableDetails.pagination.current);
      this.f_filterTable();
    },
    'p_tableDetails.sand_watch': function () {
      if (!this.p_tableDetails.sand_watch) {
        this.f_filterTable();
      }
    },
    'd_captionOptions.pagination.current': function () {
      this.d_captionOptions.pagination.start = (this.d_captionOptions.pagination.current - 1) * this.d_captionOptions.pagination.perpage;
      this.d_captionOptions.pagination.end = (this.d_captionOptions.pagination.perpage * this.d_captionOptions.pagination.current);
      this.f_filterOptions();
    },
    'd_pagination.current': function () {
      this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
      this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
      this.f_getRecordedWdmrList();
    }
  }
}

</script>

<style type="text/css">
table,
tr,
th,
td {
  border: solid 0.5px lightseagreen;
  font-size: 10px;
  text-align: center;
}

.general_scroll_class::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

.general_scroll_class::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.general_scroll_class::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.td-class {
  text-align: center;
  vertical-align: bottom;
  min-width: 100px;
  white-space: pre-line;
  border-right: solid 1px #c3d8d8;
  font-size: 10px;
  color: black,
}

.th-class {
  position: sticky;
  z-index: 1000;
  top: 0;
  white-space: pre-line;
  border-right: solid 1px #c3d8d8;
  border-bottom: solid 1px #c3d8d8;
  background-color: #ececec;
  width: 200px;
  text-align: center;
  vertical-align: bottom;
  min-width: 100px;
  background: #9ff5ff;
  background-color: aliceblue;
  font-size: 12px;
  color: black,
}

</style>

